import React from "react";
import Brick from "./components/Brick";

import "./App.css";
import ProjectBrick from "./components/ProjectBrick";

function App() {
  const arvindText =
    "Hi, I'm Arvind, a Software Engineer from Vancouver, BC, Canada. I specialize in web and mobile applications development as a full stack developer. Below are some examples of my code, and projects I've developed in my spare time.";
  const githubText =
    "Projects include: NodeJS, Java AI, Javascript, TypeScript, GraphQL, Testing Frameworks, CI/CD, Cloud, Terraforming, Git, PHP, Bootstrap, Django, React, Redux, Context Provider, Husky, Vue.";
  const leetcodeText = "Visit my leetcode profile to see some algorithms I have written and some examples of my problem solving abilities";
  const grypText =
    "Gryp is a startup I founded. I developed the entire application by myself in my free time. Gryp is a crypto marketplace. Below are some development details to demonstrate the scale of the project. The application allows inviduals to post and bid on items with crypto. The application will hold the funds in escrow until the transaction is complete.";

  return (
    <div className="App">
      <Brick
        styleName="main primary-color1"
        float={false}
        title={"Arvind Dhindsa"}
        imgSrc={process.env.PUBLIC_URL + "/images/arvind.jpg"}
        text={arvindText}
        button1="LinkedIn"
        link1="https://www.linkedin.com/in/arvind-dhindsa-156b17180/"
      />
      <Brick
        styleName="primary-color4"
        float={true}
        title={"Github Repo"}
        imgSrc={process.env.PUBLIC_URL + "/images/github.jpg"}
        text={githubText}
        button1="visit"
        link1="https://github.com/dhindsa91/"
      />
      <Brick
        styleName="primary-color2"
        float={false}
        title={"LeetCode"}
        imgSrc={process.env.PUBLIC_URL + "/images/leetcode.png"}
        text={leetcodeText}
        button1="visit"
        link1="https://leetcode.com/dnivra91/"
      />
      <ProjectBrick
        styleName="primary-color3"
        float={true}
        title={"Gryp"}
        imgSrc={process.env.PUBLIC_URL + "/images/logo-primary.svg"}
        text={grypText}
      />
    </div>
  );
}

export default App;
