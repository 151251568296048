import React from "react";

import "./Brick.css";

export default function ProjectBrick(props) {
  var main = props.styleName ? props.styleName : "";
  return (
    <div className="brick-container">
      <div className={`container ${main}`}>
        <img
          className={`avatar`}
          style={props.float ? { float: "right", objectFit: "cover" } : { float: "left", objectFit: "contain" }}
          src={props.imgSrc}
        />
        {props.title ? <h1 className={`title ${main}`}>{props.title}</h1> : ""}
        <p className={`${main}`}>{props.text}</p>
        <p style={{ backgroundColor: "#18314f" }}>
          Link:
          <a style={{ backgroundColor: "#18314f", fontSize: 24, color: "purple", textDecoration: "underline" }} href="https://lilki.ca/">
            Gryp
          </a>
        </p>
        <div
          className={`text ${main}`}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <div className="b">
            <h3 style={{ textDecoration: "underline" }}>Tech Stack</h3>
            <div>
              <div className="x">
                <div>
                  <p>Front End:</p>
                  <ul>
                    <li>TypeScript</li>
                    <li>React (Functional Components, Context Provider)</li>
                    <li>GraphQL</li>
                    <li>Jest (Automated Testing)</li>
                    <li>Sentry (Error Reporting)</li>
                    <li>Husky (Build Tool)</li>
                  </ul>
                </div>
                <div>
                  <p>Backend:</p>
                  <ul>
                    <li>NestJS (NodeJS)</li>
                    <li>TypeScript</li>
                    <li>Microservice Architecture (2 services)</li>
                  </ul>
                </div>
                <div>
                  <p>Infrastructure:</p>
                  <ul>
                    <li>AWS</li>
                    <li>MongoDB (NoSQL Database)</li>
                    <li>Redis</li>
                    <li>RabbitMQ</li>
                    <li>Elastic Search</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="a">
            <h3 style={{ textDecoration: "underline", color: "black" }}>Architecture</h3>
            <ul style={{ color: "black" }}>
              <li>Main Service - handles all incoming requests and processes most of the application functionality</li>
              <li>
                Utility Service - Handles all timed functionality including expiring offers and releasing payments. As well as sending emails and text
                messages
              </li>
            </ul>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img className="y" src={process.env.PUBLIC_URL + "/images/gryp.png"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
