import React, {useState} from 'react'


import './Brick.css'


export default function Brick(props) {
    var main = props.styleName ? props.styleName : ""

    const [showDemo, setShowDemo] = useState(false);

    return (
        <div className="brick-container">
        <div className={`container ${main}`}>
            <img className={`avatar`}  style={props.float ? {float: 'right'} :  {float: 'left'}} src={props.imgSrc}/> 
            <div className={`text ${main}`}  style={props.float ? {float: 'left'} :  {float: 'right'}}>
               { props.title ? (<h1 className={`title ${main}`}>{props.title}</h1>) : '' }
                <p className={`${main}`}>{props.text}</p>
                <a href={props.link1} target="_blank"><button variant="contained" color="primary" >{props.button1}</button></a>
                { props.link2 ?
                (<button variant="contained" color="primary"><a href={props.link2} target="_blank">{props.button2}</a></button>) :
                (props.button2 ? <button variant="contained" color="primary" onClick={()=>{ setShowDemo(!showDemo)}}>{props.button2}</button> : <></>)
                }
            </div>
        </div>
        { showDemo ? 
        (<iframe width="720" height="480" className="demo-video"
            src={props.demoLink}>
        </iframe>) : ''}
        </div>

    )
}
